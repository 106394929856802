
import { importSync as i } from '@embroider/macros';
let d = window.define;
import "@embroider/router/-embroider-implicit-modules.js";
import "ember-animated/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-cli-mirage/-embroider-implicit-modules.js";
import "ember-pikaday/-embroider-implicit-modules.js";
import "tracked-toolbox/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "ember-cli-typescript/-embroider-implicit-modules.js";
import "@algonauti/ember-active-storage/-embroider-implicit-modules.js";
import "@ember/jquery/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "@ember/test-waiters/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "ember-ajax/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "ember-api-actions/-embroider-implicit-modules.js";
import "ember-cli-app-version/-embroider-implicit-modules.js";
import "ember-cli-dependency-checker/-embroider-implicit-modules.js";
import "ember-test-selectors/-embroider-implicit-modules.js";
import "ember-cli-deprecation-workflow/-embroider-implicit-modules.js";
import "ember-cli-inline-content/-embroider-implicit-modules.js";
import "ember-cli-segment/-embroider-implicit-modules.js";
import "ember-cli-showdown/-embroider-implicit-modules.js";
import "ember-cli-postcss/-embroider-implicit-modules.js";
import "broccoli-asset-rev/-embroider-implicit-modules.js";
import "ember-cli-terser/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "ember-data/-embroider-implicit-modules.js";
import "ember-cli-typescript-blueprints/-embroider-implicit-modules.js";
import "ember-composable-helpers/-embroider-implicit-modules.js";
import "ember-concurrency/-embroider-implicit-modules.js";
import "ember-export-application-global/-embroider-implicit-modules.js";
import "ember-fetch/-embroider-implicit-modules.js";
import "ember-in-viewport/-embroider-implicit-modules.js";
import "ember-intl/-embroider-implicit-modules.js";
import "ember-load-initializers/-embroider-implicit-modules.js";
import "ember-math-helpers/-embroider-implicit-modules.js";
import "ember-maybe-import-regenerator/-embroider-implicit-modules.js";
import "ember-model-validator/-embroider-implicit-modules.js";
import "ember-truth-helpers/-embroider-implicit-modules.js";
import "ember-power-select/-embroider-implicit-modules.js";
import "ember-promise-helpers/-embroider-implicit-modules.js";
import "ember-resolver/-embroider-implicit-modules.js";
import "ember-set-helper/-embroider-implicit-modules.js";
import "ember-svg-jar/-embroider-implicit-modules.js";
import "ember-user-activity/-embroider-implicit-modules.js";
import "ember-window-mock/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
import "qunit-dom/-embroider-implicit-modules.js";
import "ember-local-storage/-embroider-implicit-modules.js";
